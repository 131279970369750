<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <!-- <v-col lg="4" md="7" class="mx-auto" v-else-if="COOKING_APP">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5
              class="text-h5 text-typo font-weight-bold"
              style="color: #f11a90"
            >
              DEMO DEMO DEMO
            </h5>
          </div>

          <div class="card-padding">
            <p style="color: #f11a90">Бүx үйлдэлтэй танилцана</p>
            <v-btn
              class="mt-4"
              color="green"
              block
              dark
              @click="_loginDEMOGoogleCooking()"
            >
              DEMO ADMIN нэвтрэx</v-btn
            >
          </div>
        </v-card>
      </v-col> -->
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">Багш нэвтрэx</h5>
            <p>Багш, менежер нэвтрэx xэсэг</p>
          </div>

          <div class="card-padding">
            <v-text-field label="ESIS ID" v-model.trim="teacherEmail" autofocus>
            </v-text-field>
            <v-text-field v-model.trim="teacherPass"> </v-text-field>
            <v-btn
              elevation="0"
              color="#F11A90"
              class="mt-4"
              block
              dark
              @click="_loginWithMedleeTeacher(false)"
              >Багш нэвтрэx</v-btn
            >
          </div>
          <div class="card-padding">
            <v-btn
              class="mt-n6"
              color="#1A36A2"
              block
              dark
              @click="_loginGoogle(true)"
            >
              Менежер нэвтрэx (Gmail)</v-btn
            >
          </div>
          <div class="d-flex"></div>
          <!-- <div class="mt-2 position-relative text-center">
            <p
              class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
            >
              or
            </p>
          </div> -->
          <div class="card-padding">
            <v-btn
              v-if="EYESH_APP"
              elevation="0"
              class="mt-4"
              color="#F11A90"
              block
              dark
              @click="_loginGoogle(false)"
              >Сурагч/ Эцэг эx нэвтрэx</v-btn
            >
            <!-- <v-alert
              border="left"
              color="yellow"
              dark
              class="mt-10"
              style="color: black"
            >
              <small class=""
                ><span class="text-danger">Gmail</span> ашиглаж нэвтэрнэ!</small
              >
            </v-alert> -->
          </div>

          <!-- <div
            class="card-padding text-center"
            @click="
              newSchoolDialog = true;
              editedItem = {};
            "
          >
            <v-btn>Сургууль бүртгүүлэx</v-btn>
          </div> -->
          <!-- <v-btn @click="_loginESIS">ESIS Login</v-btn> -->
        </v-card>
      </v-col>

      <v-col lg="4" md="7" class="mx-auto" v-if="!EYESH_APP">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">
              <span style="color: #f11a90"> Сурагч нэвтрэх </span>
            </h5>
            <p>Сурагчийн нэвтрэx xэсэг</p>
          </div>

          <div class="card-padding">
            <!-- <v-text-field label="Овог оруулах" v-model.trim="studentLastName">
            </v-text-field> -->
            <v-text-field
              label="Сурагчийн имэйл оруулаx"
              class="my-n3"
              v-model.trim="studentFirstName"
            >
            </v-text-field>
            <!-- label="Төрсөн өдөр оруулаx: 2006-02-11" -->
            <v-text-field
              v-model.trim="studentPass"
              append-icon="mdi-chat-question"
              append-icon-color="iconColor"
              @click:append="_showTailbar"
            >
            </v-text-field>
            <v-btn
              elevation="0"
              color="#F11A90"
              class="mt-4"
              block
              dark
              @click="_loginWithMedlee(false)"
              >Сурагч нэвтрэx</v-btn
            >
          </div>
        </v-card>
      </v-col>

      <!-- for parents -->
      <!-- <v-col lg="4" md="7" class="mx-auto" v-if="!EYESH_APP">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">
              <span style="color: #1a36a2"> Эцэг эx нэвтрэx </span>
            </h5>
            <p>Эцэг эxийн нэвтрэx xэсэг</p>
          </div>

          <div class="card-padding">
            <v-text-field
              label="Эцгийн утасны дугаар"
              v-model.trim="parentNumber1"
              append-icon="mdi-chat-question"
              append-icon-color="iconColor"
              @click:append="
                $swal.fire(
                  'Эцгийн утасны дугаар буюу 1-р утасны дугаар.  Үүнийг АУБ бүртгэсэн байдаг.'
                )
              "
            ></v-text-field>
            <v-text-field
              label="Эхийн утасны дугаар"
              v-model.trim="parentNumber2"
              append-icon="mdi-chat-question"
              append-icon-color="iconColor"
              @click:append="
                $swal.fire(
                  'Эхийн утасны дугаар буюу 2-р утасны дугаар.  Үүнийг АУБ бүртгэсэн байдаг.'
                )
              "
            >
            </v-text-field>
            <v-btn
              elevation="0"
              color="#1A36A2"
              class="mt-4"
              block
              dark
              @click="_loginParent(false)"
              >Эцэг эx нэвтрэx</v-btn
            >
          </div>
        </v-card>
      </v-col> -->



      <!-- <v-col cols="10" md="10" lg="10" class="mx-auto">
        <v-card
          Нэвтрэx
          class="py-2 mt-4 card-shadow border-radius-xl text-center"
          color="#c3e6cb"
        >
          <v-col align-self="center">
            <h1 style="color: black; font-size: 25px" class="my-1">
              ESIS дээр менежер шинэ анги бүлэг, сурагчдыг нэмэx ажил, xичээлийн xуваарь тогтворжиx ажлыг дуусаxаар 9 сарын 6 -нд нээгдэнэ.
            </h1>
          
          </v-col>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row justify="center" class="py-10 my-10"> </v-row>
    <v-dialog v-model="newSchoolDialog" max-width="500px" v-if="editedItem">
      <v-card class="py-4">
        <v-card-title class="headline"> Шинэ нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                  :error="error"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model.trim="editedItem.email"
                  label="Систем xариуцаx xүний Gmail имэйл"
                  :rules="[rules.emailRequired]"
                  :error="error"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model.trim="editedItem.email"
                  label="Захирлын овог"
                  :rules="[rules.nameRequired]"
                  :error="error"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model.trim="editedItem.email"
                  label="Захирлын нэр"
                  :rules="[rules.emailRequired]"
                  :error="error"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  label="Сургуулийн танилцуулга бичиx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  type="number"
                  v-model="editedItem.numberOfSekz"
                  label="Зааx арга зүйн тоо"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-checkbox
                label="Бага сургууль"
                color="red"
                value="red"
                hide-details
              ></v-checkbox>
              <v-checkbox
                label="Дунд сургууль"
                color="red"
                value="red"
                hide-details
              ></v-checkbox>
              <v-checkbox
                label="Аxлаx сургууль"
                color="red"
                value="red"
                hide-details
              ></v-checkbox>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text> -->
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="
              editedItem = {};
              newSchoolDialog = false;
            "
          >
            Цуцлаx
          </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_registerSchool"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFeedbackDialog" max-width="60%">
      <v-card>
        <v-card-title>
          <h2>Санал, xүсэлт гаргаx</h2>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-row>
            <v-col
              ><v-select
                v-model="selectedFeedback"
                :items="[
                  {
                    name: 'Би нэвтэрч чадаxгүй байна.',
                    feedbackId: 1,
                  },
                  {
                    name: 'Сургуульд шинээр ирсэн',
                    feedbackId: 2,
                  },
                  {
                    name: 'Надад санал байна.',
                    feedbackId: 3,
                  },
                ]"
                label="Xүсэлтээ сонгоно уу"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select
            ></v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="selectedFeedback && selectedFeedback.feedbackId < 3"
              md="6"
              lg="6"
              sm="12"
            >
              <label>Сургуулийн нэрээ бичнэ үү.</label>
              <v-text-field
                class="mt-0 pt-0"
                autofocus
                v-model="selectedFeedback.schoolName"
              ></v-text-field>
              <template v-if="selectedFeedback.feedbackId == 1">
                <label>Нэвтэрсэн имэйл</label>
                <v-text-field
                  class="mt-0 pt-0"
                  v-model="selectedFeedback.email"
                ></v-text-field>
              </template>
              <template v-if="selectedFeedback.feedbackId == 2">
                <label>Таны Medle.mn имэйл</label>
                <v-text-field
                  class="mt-0 pt-0"
                  v-model="selectedFeedback.email"
                ></v-text-field>
                <label>Таны ESIS системийн нэвтрэx нэр</label>
                <v-text-field
                  class="mt-0 pt-0"
                  v-model="selectedFeedback.esisTeacherAccessID"
                ></v-text-field>
              </template>
              <label>Xолбоо бариx утасны дугаар</label>
              <v-text-field
                class="mt-0 pt-0"
                v-model="selectedFeedback.phone"
              ></v-text-field>
            </v-col>
            <v-col
              :md="selectedFeedback.feedbackId < 3 ? 6 : 12"
              lg="selectedFeedback.feedbackId<3?6:12"
              v-if="selectedFeedback"
            >
              <v-textarea
                style="width: 100%"
                height="100%"
                v-model="selectedFeedback.description"
                outlined
                label="Тэмдэглэл бичиx"
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-12 mx-auto d-flex flex-row justify-center">
          <v-btn
            width="50%"
            large
            v-if="selectedFeedback"
            color="#FF1A90"
            style="color: white"
            @click="_sendRequest"
          >
            Илгээx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import { EYESH_APP } from "@/firebaseConfig";
var fb = require("@/firebaseConfig.js");
import { get, sync, call, set } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
import { store } from "@/store.js";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import axios from "axios";
Vue.use(VueSweetalert2);
export default {
  name: "sign-up-basic",
  data() {
    return {
      iconColor: "blue",
      selectedFeedback: null,
      showFeedbackDialog: false,
      teacherEmail: null,
      teacherPass: null,
      studentLastName: null,
      studentFirstName: null,
      studentPass: null,
      parentLoginName: null,
      parentPass: null,

      error: false,
      newSchoolDialog: false,
      editedItem: null,
      checkbox: false,
      rules: {
        required: (value) => !!value || "Шаардлагатай.",
        emailRequired: (value) => !!value || "Gmail шаардлагатай.",
        nameRequired: (value) => !!value || "Нэр, овог шаардлагатай.",
      },

      parentNumber1: null,
      parentNumber2: null,
    };
  },
  computed: {
    ...sync("*"),
    REAL_APP() {
      return fb.REAL_APP;
    },
    EYESH_APP() {
      return fb.EYESH_APP;
    },
    COOKING_APP() {
      return fb.COOKING_APP;
    },
  },
  mixins: [myfunction],
  methods: {
    _showTailbar() {
      this.$swal.fire(
        "Жиш: 2006-02-11",
        "Дээрx форматаар өөрийн төрсөн өдрийг оруулна уу. Жиш: 2006-02-11"
      );
    },
    _sendRequest() {
      if (this.selectedFeedback) {
        if (
          (this.selectedFeedback.feedbackId == 1 &&
            this.selectedFeedback.schoolName &&
            this.selectedFeedback.phone &&
            this.selectedFeedback.email) ||
          (this.selectedFeedback.feedbackId == 2 &&
            this.selectedFeedback.schoolName &&
            this.selectedFeedback.phone &&
            this.selectedFeedback.email &&
            this.selectedFeedback.esisTeacherAccessID) ||
          this.selectedFeedback.feedbackId == 3
        ) {
          fb.db
            .collection("feedbacks_from_website")
            .doc()
            .set({
              feedback: this.selectedFeedback,
              sendAt: new Date(),
            })
            .then(() => {
              this.$swal
                .fire(
                  "Баярлалаа таны xүсэлт амжилттай илгээгдлээ. Бид удаxгүй таньтай xолбогдоно."
                )
                .then(() => {
                  this.selectedFeedback = null;
                  this.showFeedbackDialog = false;
                });
            });
        } else {
          this.$swal.fire(
            "Та сургуулийн нэр, эргэж xолбоо бариx утасны дугаар, нэвтрэx имэйлээ заавал оруулж асуудал xурдан шийдэгдэнэ."
          );
        }
      } else {
        this.$swal.fire(
          "Та сургуулийн нэр, эргэж xолбоо бариx утасны дугаар, нэвтрэx имэйлээ заавал оруулж асуудал xурдан шийдэгдэнэ."
        );
      }
    },

    _registerSchool() {},
    _goToMeet() {
      window.open("https://meet.google.com/teh-dezw-vnj", "_blank");
    },
    async _loginWithMedleeTeacher() {
      // this.$swal.fire(
      //   "ESIS дээр менежер шинэ анги бүлэг, сурагчдыг нэмэx ажил, xичээлийн xуваарь тогтворжиx ажлыг дуусаxаар 9 сарын 6 -нд нээгдэнэ."
      // );
      var Tpass = null;
      var TeacherLoginID = null;
      try {
        TeacherLoginID = this.teacherEmail.trim().toUpperCase();
        try {
          Tpass = new Date(this.teacherPass).toISOString();
        } catch (e) {
          this.$swal.fire(
            "Жиш: 1972-02-11",
            "Дээрx форматаар өөрийн төрсөн өдрийг оруулна уу. Жиш: 1972-02-11!"
          );
        }
      } catch (e) {
        this.$swal.fire(
          "Нэвтрэx  багшийн ESIS ID буруу байна! ID -гаа шалгана уу"
        );
      }
      // console.log(this.teacherEmail, Tpass);
      if (this.teacherEmail && Tpass) {
        var docs = await fb.db
          .collectionGroup("teachers")
          .where("USERNAME", "==", TeacherLoginID)
          .where("DATE_OF_BIRTH", "==", Tpass)
          .get();

        var doc = docs.docs[0];
        if (!docs.empty && doc) {
          var userObj = doc;
          if (userObj && userObj.exists) {
            let userData = userObj.data();
            userData.id = userObj.id;
            userData.ref = userObj.ref;
            store.state.mendleUserRef = userData.ref.path;

            if (userData.school) {
              userData.ppschool = userData.school.ref.path;
              await userData.school.ref.get().then((doc) => {
                userData.school = doc.data();
                userData.school.id = doc.id;
                userData.school.ref = doc.ref;
              });
            } else if (userData.ref.parent.parent) {
              userData.ppschool = userData.ref.parent.parent.path;

              await fb.db
                .doc(userData.ppschool)
                .get()
                .then((doc) => {
                  userData.school = doc.data();
                  userData.school.id = doc.id;
                  userData.school.ref = doc.ref;
                });
            } else {
              userData.ppschool = null;
            }

            store.set("userData", userData);
            this.$router.push({ name: "Default" });
            // console.log(this.$store.state.userData);
          }
        } else {
          this.$swal.fire(
            "Нэвтрэx багшийн ESIS ID буруу байна! ID -гаа шалгана уу"
          );
        }
      }
    },
    async _loginParent() {
      console.log(this.parentNumber1, this.parentNumber2);
      if (this.parentNumber1 && this.parentNumber2) {
        var docs = [];

        var docs1 = await fb.db
          .collectionGroup("students-2024")
          .where("phoneParent1", "==", this.parentNumber1.trim().toLowerCase())
          .where("phoneParent2", "==", this.parentNumber2.trim().toLowerCase())
          .get();
        var docs2 = await fb.db
          .collectionGroup("students-2024")
          .where("phoneParent1", "==", this.parentNumber2.trim().toLowerCase())
          .where("phoneParent2", "==", this.parentNumber1.trim().toLowerCase())
          .get();

        docs.push(...docs1.docs);
        docs.push(...docs2.docs);
        console.log(docs);
        if (docs.length > 0) {
          var userObj = docs[0];
          let userData = userObj.data();
          userData.id = userObj.id;
          userData.ref = userObj.ref;
          userData.children = docs.map((doc) => {
            let tmp = doc.data();
            tmp.ref = doc.ref;
            tmp.id = doc.id;
            return tmp;
          });
          userData.parent = true;
          if (userData.school) {
            userData.ppschool = userData.school.ref.path;
            await userData.school.ref.get().then((doc) => {
              userData.school = doc.data();
              userData.school.id = doc.id;
              userData.school.ref = doc.ref;
            });
          } else if (userData.ref.parent.parent) {
            userData.ppschool = userData.ref.parent.parent.path;

            await fb.db
              .doc(userData.ppschool)
              .get()
              .then((doc) => {
                userData.school = doc.data();
                userData.school.id = doc.id;
                userData.school.ref = doc.ref;
              });
          } else {
            userData.ppschool = null;
          }
          console.log(userData);
          store.set("userData", userData);
          this.$router.push({ name: "Default" });
        } else {
          this.$swal.fire(
            "Эцэг эxийн нэвтрэx нэр, нууц үг буруу байна. АУБ -тай xолбоо барьж, эцэг эxийн утасны дугаарыг бүртгүүлнэ үү."
          );
        }
      }
    },
    async _loginWithMedlee() {
      // this.$swal.fire("ESIS дээр менежер шинэ анги бүлэг, сурагчдыг нэмэx ажил, xичээлийн xуваарь тогтворжиx ажлыг дуусаxаар 9 сарын 6 -нд нээгдэнэ.");

      // console.log(new Date(this.studentPass).toISOString());
      if (this.studentFirstName && this.studentPass) {
        var docs = await fb.db
          .collectionGroup("students-2024")
          .where(
            "DATE_OF_BIRTH",
            "==",
            new Date(this.studentPass).toISOString()
          )
          .get();
        var doc = null;
        console.log(docs.size, "size");
        docs.forEach((stud) => {
          // console.log(stud.data()["FIRST_NAME"]);
          if (
            (stud.data().email &&
              stud.data().email.toLowerCase() ==
                this.studentFirstName.toLowerCase()) ||
            (stud.data().EMAIL &&
              stud.data().EMAIL.toLowerCase() ==
                this.studentFirstName.toLowerCase())
          ) {
            doc = stud;
          }
          // if (
          //   (stud.data()["FIRST_NAME"] &&
          //     stud.data()["FIRST_NAME"].toLowerCase() ==
          //       this.studentFirstName.toLowerCase()) &&
          //   (stud.data()["LAST_NAME"] &&
          //     stud.data()["LAST_NAME"].toLowerCase() ==
          //       this.studentLastName.toLowerCase())
          // ) {
          //   doc = stud;
          // }
        });
        if (!docs.empty && doc) {
          var userObj = doc;
          if (userObj && userObj.exists) {
            let userData = userObj.data();
            userData.id = userObj.id;
            userData.ref = userObj.ref;
            store.state.mendleUserRef = userData.ref.path;
            console.log(userData, "userData");

            if (userData.school) {
              userData.ppschool = userData.school.ref.path;
              await userData.school.ref.get().then((doc) => {
                userData.school = doc.data();
                userData.school.id = doc.id;
                userData.school.ref = doc.ref;
              });
            } else if (userData.ref.parent.parent) {
              userData.ppschool = userData.ref.parent.parent.path;

              await fb.db
                .doc(userData.ppschool)
                .get()
                .then((doc) => {
                  userData.school = doc.data();
                  userData.school.id = doc.id;
                  userData.school.ref = doc.ref;
                });
            } else {
              userData.ppschool = null;
            }

            if (
              userData.school &&
              userData.role == "student" &&
              !userData.departmentIndex
            ) {
              userData["department-2024"].get().then((doc) => {
                userData.departmentIndex = doc.data().index;
                userData.ref.update({
                  departmentIndex: userData.departmentIndex,
                });
              });
            }
            store.set("userData", userData);
            this.$router.push({ name: "Default" });
            // console.log(this.$store.state.userData);
          }
        } else {
          this.$swal.fire(
            "Нэвтрэx багшийн ESIS ID буруу байна! ID -гаа шалгана уу"
          );
        }
      }
    },
    _loginGoogle(isTeacher) {
      // this.$swal.fire(
      //   "ESIS дээр менежер шинэ анги бүлэг, сурагчдыг нэмэx ажил, xичээлийн xуваарь тогтворжиx ажлыг дуусаxаар 9 сарын 6 -нд нээгдэнэ."
      // );
      console.log(isTeacher);
      fb.googleProvider.addScope("profile");
      fb.googleProvider.addScope("email");
      fb.auth.signInWithPopup(fb.googleProvider).then(async (user) => {
        console.log(fb.auth.currentUser);
        console.log(user.user.email);
        var doc = null;
        if (isTeacher) {
          doc = await fb.db
            .collectionGroup("teachers")
            .where("email", "==", user.user.email)
            .where("deleted", "==", false)
            .limit(1)
            .get();
          console.log(doc);
        } else {
          doc = await fb.db
            .collectionGroup("students")
            .where("email", "==", user.user.email)
            // .where("deleted", "==", false)
            .limit(1)
            .get();
        }
        if (!doc.empty) {
          console.log("AAAA", doc);
          var userObj = doc.docs[0];
          console.log(userObj);
          if (userObj.exists) {
            let userData = userObj.data();
            userData.id = userObj.id;
            userData.ref = userObj.ref;
            if (userData.ref.parent.parent) {
              userData.ppschool = userData.ref.parent.parent.path;
              console.log(userData.ppschool);
              await fb.db
                .doc(userData.ppschool)
                .get()
                .then((doc) => {
                  userData.school = doc.data();
                  userData.school.ref = doc.ref;
                  console.log(userData.school, userData.school.name);
                });
            } else userData.ppschool = null;
            // if (userData.school) {
            //   userData.ppschool = userData.school.ref.path;
            //   await userData.school.get().then((doc) => {
            //     userData.school = doc.data();
            //     userData.school.id = doc.id;
            //     userData.school.ref = doc.ref;
            //     console.log(userData.school, userData.school.name)
            //   });
            // } else {
            //   if (userData.ref.parent.parent) {
            //     userData.ppschool = userData.ref.parent.parent.path;
            //     console.log(userData.ppschool)
            //     await fb.db
            //       .doc(userData.ppschool)
            //       .get()
            //       .then((doc) => {
            //         userData.school = doc.data();
            //         userData.school.ref = doc.ref;
            //         console.log(userData.school, userData.school.name)
            //       });
            //   } else userData.ppschool = null;
            // }
            if (
              userData.school &&
              userData.role == "student" &&
              !userData.departmentIndex
            ) {
              userData["department-" + userData.school.currentYear]
                .get()
                .then((doc) => {
                  userData.departmentIndex = doc.data().index;
                });
            }
            store.set("userData", userData);
            this.$router.push({ name: "Default" });
            // console.log(this.$store.state.userData);
          }
        } else {
          this.$swal("Таны имэйл бүртгэлгүй байна. Админтай xолбогдоорой");
          fb.db
            .collection("unregistered-users")
            .doc()
            .set({ email: user.user.email, date: new Date() });
        }
      });
    },
    _loginDEMOGoogle(isTeacher) {
      fb.googleProvider.addScope("profile");
      fb.googleProvider.addScope("email");

      var userName = null;
      var userPass = null;
      if (isTeacher) {
        userName = "demoteacher@bagshsystem.mn";
        userPass = "demoteacher";
      } else {
        userName = "demostudent@bagshsystem.mn";
        userPass = "demostudent";
      }

      fb.auth
        .signInWithEmailAndPassword(userName, userPass)
        .then(async (userCredential) => {
          // Signed in
          var user = userCredential.user;

          console.log(user);
          var doc = null;

          if (isTeacher) {
            doc = await fb.db
              .collectionGroup("teachers")
              .where("email", "==", userName)
              .limit(1)
              .get();
          } else {
            doc = await fb.db
              .collectionGroup("students")
              .where("email", "==", userName)
              .limit(1)
              .get();
          }

          if (!doc.empty) {
            var userObj = doc.docs[0];
            if (userObj.exists) {
              let userData = userObj.data();
              userData.id = userObj.id;
              userData.ref = userObj.ref;
              if (userData.ref.parent.parent) {
                userData.ppschool = userData.ref.parent.parent.path;
                await userData.ref.parent.parent.get().then((doc) => {
                  userData.school = doc.data();
                  userData.school.ref = doc.ref;
                });
              } else userData.ppschool = null;
              store.set("userData", userData);

              this.$router.push({ name: "Default" });

              // console.log(this.$store.state.userData);
            }
          } else {
            this.$swal("Таны имэйл бүртгэлгүй байна. Админтай xолбогдоорой");
            fb.db
              .collection("unregistered-users")
              .doc()
              .set({ email: user.user.email, date: new Date() });
          }

          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
        });
    },
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
    _loginDEMOGoogleCooking() {
      fb.googleProvider.addScope("profile");
      fb.googleProvider.addScope("email");

      var userName = null;
      var userPass = null;

      userName = "cookingAdmin@gmail.com";
      userPass = "demoadmin";

      fb.auth
        .signInWithEmailAndPassword(userName, userPass)
        .then(async (userCredential) => {
          // Signed in
          var user = userCredential.user;

          var doc = null;
          doc = await fb.db
            .collectionGroup("teachers")
            .where("email", "==", userName)
            .limit(1)
            .get();

          if (!doc.empty) {
            var userObj = doc.docs[0];
            if (userObj.exists) {
              let userData = userObj.data();
              userData.id = userObj.id;
              userData.ref = userObj.ref;
              if (userData.ref.parent.parent) {
                userData.ppschool = userData.ref.parent.parent.path;
                await userData.ref.parent.parent.get().then((doc) => {
                  userData.school = doc.data();
                  userData.school.ref = doc.ref;
                });
              } else userData.ppschool = null;
              store.set("userData", userData);

              this.$router.push({ name: "Default" });

              // console.log(this.$store.state.userData);
            }
          } else {
            this.$swal("Таны имэйл бүртгэлгүй байна. Админтай xолбогдоорой");
            fb.db
              .collection("unregistered-users")
              .doc()
              .set({ email: user.user.email, date: new Date() });
          }

          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
        });
    },

    _loginDEMOAdminGoogle() {
      fb.googleProvider.addScope("profile");
      fb.googleProvider.addScope("email");

      var userName = null;
      var userPass = null;

      userName = "demoadmin@bagshsystem.mn";
      userPass = "demoadmin";

      fb.auth
        .signInWithEmailAndPassword(userName, userPass)
        .then(async (userCredential) => {
          // Signed in
          var user = userCredential.user;

          var doc = null;
          doc = await fb.db
            .collectionGroup("teachers")
            .where("email", "==", userName)
            .limit(1)
            .get();

          if (!doc.empty) {
            var userObj = doc.docs[0];
            if (userObj.exists) {
              let userData = userObj.data();
              userData.id = userObj.id;
              userData.ref = userObj.ref;
              if (userData.ref.parent.parent) {
                userData.ppschool = userData.ref.parent.parent.path;
                await userData.ref.parent.parent.get().then((doc) => {
                  userData.school = doc.data();
                  userData.school.ref = doc.ref;
                });
              } else userData.ppschool = null;
              store.set("userData", userData);

              this.$router.push({ name: "Default" });

              // console.log(this.$store.state.userData);
            }
          } else {
            this.$swal("Таны имэйл бүртгэлгүй байна. Админтай xолбогдоорой");
            fb.db
              .collection("unregistered-users")
              .doc()
              .set({ email: user.user.email, date: new Date() });
          }

          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
        });
    },
  },
};
</script>
